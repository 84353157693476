import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ReactTable from "react-table";

import 'react-table/react-table.css'
import './subpage.css'

import Layout from '../components/layout'
import PromoBlock from '../components/promoBlock'

export default ({ data }) => {
  const customOptionsFilter = ({filter, onChange}) => {
    var unique = [];
    data.allSoftwareJson.edges[0].node.software.map(( software , index) => {
      if(!unique.includes(software.category)) {
        unique.push(software.category);
      }
      return null
    })
    return(
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <div className="mobile-show">Category Filter:</div>
        <select
          onChange={e => onChange(e.target.value)}
          style={{  width: '100%'  }}
          value={filter ? filter.value : 'all'}
          >
          <option value='all'>Show All</option>
          {
            unique.sort().map((category , index) => {
              return <option key={index} value={category}>{category}</option>
            })
          }
        </select>
      </div>
    )
  }
  console.log(data);

  const columns = [{
    expander: true,
    show: false
  }, {
    Header: 'Software',
    accessor: 'node',
    width: 'auto',
    Cell: props => (
      props.original.featured ? (
        <div>
          <span role="img" aria-label="star">⭐️</span> 
          <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.name}</a> 
          <span role="img" aria-label="star">⭐️</span>
        </div>
      ) : (
        <a href={props.original.link+"?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{props.original.name}</a>
      )
    )
  }, {
    Header: 'Category',
    accessor: 'category',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Category: </strong>{props.value}</span>
    ),
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }) => customOptionsFilter({filter, onChange})
  }, {
    Header: 'Price',
    accessor: 'price',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show">Price: </strong>{props.value}</span>
    )
  }, {
    Header: 'Pricing Filter',
    accessor: 'pricingFilter',
    width: 'auto',
    Cell: props => (
      <span><strong className="mobile-show" style={{marginBottom: 5}}>Pricing Filter: </strong>{props.value}</span>
    )
  }, {
    Header: 'Speciality',
    accessor: 'why',
    width: 'auto'
  }, {
    Header: '',
    id: 'isFeatured',
    accessor: 'featured',
    show: false
  }]

  return (
    <Layout>

      <Helmet>
        <title>AlphaDex | The Best Amazon Software</title>
        <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best software tools to help you with your specific amazon store needs." />
        <meta name="keywords" content="amazon, selling, software, tools" />
      </Helmet>

      <div>
        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred software</h1>
        <ReactTable
          data={data.allSoftwareJson.edges[0].node.software}
          columns={columns}
          showPagination={false}
          sortable={true}
          sorted={[
            {
              id: 'isFeatured',
              desc: true
            }
          ]}
          minRows={0}
          resizable={false}
          defaultPageSize={data.allSoftwareJson.edges[0].node.software.length}
          SubComponent={row => {
            return (
              row.row._original.isFeatured &&
                <PromoBlock featuredText={row.row._original.promo.featuredText} featuredImage={row.row._original.promo.featuredImage} link={row.row._original.link} />
            );
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return{
              'data-qnt': rowInfo.original.featured,
            }
          }}
        />

      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allSoftwareJson {
      edges {
        node {
          software{
            name,
            link,
            category,
            price,
            pricingFilter,
            why,
            featured,
            promo{
              featuredImage
            }
          }
        }
      }
    }
  }
`